import general from "./general";
import menu from "./menu";
import home from "./home";
import footer from "./footer";
import validation from "./validation";
import video from "./video";

export default {
  general: general,
  menu: menu,
  home: home,
  footer: footer,
  validation: validation,
  video: video,
};
