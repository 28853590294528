<script setup>
  import {defineProps, defineExpose, ref, computed, watch, onMounted} from 'vue'

  const props = defineProps({
    speed: {
      Type: Number,
      Default: 100
    },
    pause: {
      Type: Number,
      Default: 1000
    },
    texts: {
      Type: Array,
      Default: ["Code Nidus"]
    },
    lastCharacterClass: {
      Type: String,
      Default: ""
    },
    start: {
      type: Boolean,
      default: true
    },
    oneWay: {
      type: Boolean,
      default: false
    }
  })

  const interval = ref()
  const waiting = ref(false)
  const backward = ref(false)
  const letterIndex = ref(0)
  const currentIndex = ref(0)
  const currentText = ref('')

  const currentTextWithLastCharacter = computed(() => {
    return (
        currentText?.value.substring(0, currentText.value.length - 1) +
        `<span class="${props.lastCharacterClass}">${currentText.value.charAt(
            currentText.value.length - 1
        )}</span>`
    )
  })

  watch(() => props.start, (value) => {
    if (value) {
      initiate()
    }
  })

  const wait = (ms) => {
    waiting.value = true;
    setTimeout(() => {
      waiting.value = false;
    }, ms);
  }

  const startTyping = () => {
    if (waiting.value) return;

    if (!backward.value) {
      currentText.value += props.texts[currentIndex.value].substr(letterIndex.value, 1);

      if (letterIndex.value === props.texts[currentIndex.value].length - 1) {
        currentText.value += ' '
      }
    } else {
      currentText.value = currentText.value.substring(0, letterIndex.value);
    }

    letterIndex.value += !backward.value ? 1 : -1;

    if (!backward.value &&  letterIndex.value === props.texts[currentIndex.value].length + 1) {
      if (props.texts.length === 1 && props.oneWay) {
        clearInterval(interval.value);
      } else {
        backward.value = true;
        wait(props.pause);
      }

      return;
    }

    if (backward.value && letterIndex.value === -1) {
      currentIndex.value++;
      letterIndex.value = 0;
      backward.value = false;
    }

    if (currentIndex.value >= props.texts.length) {
      currentIndex.value = 0;
    }
  }

  const reset = () => {
    interval.value = null
    waiting.value = false
    backward.value = false
    letterIndex.value = 0
    currentIndex.value = 0
    currentText.value = ''
  }

  const initiate = () => {
    interval.value = setInterval(startTyping, parseInt(props.speed));
  }

  onMounted(() => {
    if (props.start) {
      initiate()
    }
  })

  defineExpose({
    reset,
  })
</script>

<template>
  <div class="typer">
    <label v-html="currentTextWithLastCharacter"></label>
  </div>
</template>



<style scoped></style>
