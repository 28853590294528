import axios from "axios";
import configs from "@/configs"

const apiClient = axios.create({
  baseURL: configs.axios.baseURL,
  withCredentials: false,
  crossDomain: true,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default apiClient;
