<script setup>
  import { ref, onMounted } from 'vue';
  import Input from '@/components/Form/Input';
  import { useReCaptcha } from 'vue-recaptcha-v3'
  import axios from '@/utils/Axios'

  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

  const contact = ref({
    name: 'test',
    email: null,
    phone: null,
    company: null,
    file: null,
    comments: null,
    interestItem: null,
    token: null
  })

  const interestItems = ref([
    "software",
    "website",
    "mobileApp",
    "itStaffing",
    "managedServices"
  ])

  const inputsItems = ref([])
  const captchaItem = ref()
  const fileUploader = ref()
  const validate = ref(true)
  const message = ref(null)
  const loading = ref(false)
  const status = ref(null)

  const submitForm = async (e) => {
    e.preventDefault()

    loading.value = true

    await validateForm()

    if (!validate.value) {
      loading.value = false
      return false
    }

    await recaptchaLoaded()
    const token = await executeRecaptcha('login')

    if (!token) {
      loading.value = false
      return false
    }

    contact.value.token = token;

    sendForm();
  }
  const validateForm = async () => {
    validate.value = true

    inputsItems.value.forEach(item => {
      if (typeof item.rulesCheck !== "undefined") {
        const status = item.rulesCheck()
        validate.value = status !== true ? false : validate.value
      }
    })
  }
  const sendForm = () => {
    status.value = null;
    const contactFormData = new FormData();

    for (const [key, value] of Object.entries(contact.value)) {
      contactFormData.append(key, value)
    }

    axios({
      method: "post",
      url: "mail/contact/sendMail",
      baseURL: "/",
      data: contactFormData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response => {
      message.value = response.data.message;
      contact.value = {
        name: null,
        email: null,
        phone: null,
        company: null,
        file: null,
        comments: null,
        interestItem: null,
        token: null
      }

      fileUploader.value.removeFile()

      status.value = true
    },
    error => {
      message.value = error.message
      status.value = false
    }).finally(() => {
      loading.value = false
      // captchaItem.value.reset()
    });
  }

  onMounted(() => {
    contact.value.interestItem = interestItems.value[0];
  })
</script>

<template>
  <div class="row page-area contact">
    <div class="col-sm-12">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-sm-12 col-md-11 m-auto">
            <h3 class="title">{{ $t("home.contact.title") }}</h3>
            <p class="sub-title">{{ $t("home.contact.areaOfInterest") }}</p>
            <div class="interest_items">
              <div
                v-for="(interest, index) in interestItems"
                :key="index"
                class="interest_item"
              >
                <input
                  type="radio"
                  :id="'interest_item_' + index"
                  v-model="contact.interestItem"
                  :value="interest"
                  class="doc"
                />
                <label :for="'interest_item_' + index" class="doc">
                  {{ $t("home.contact." + interest) }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row contact-form">
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => { inputsItems.push(el) }"
              v-model:modelValue="contact.name"
              :label="$t('home.contact.name')"
              rules="required"
            />
          </div>
          <div class="col-md-2 hidden-sm"></div>
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => { inputsItems.push(el) }"
              v-model:modelValue="contact.email"
              :label="$t('home.contact.email')"
              :rules="['required', 'email']"
            />
          </div>
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => { inputsItems.push(el) }"
              v-model:modelValue="contact.phone"
              :label="$t('home.contact.phone')"
              :rules="['required', 'phone']"
            />
          </div>
          <div class="col-md-2 hidden-sm"></div>
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => { inputsItems.push(el) }"
              v-model:modelValue="contact.company"
              :label="$t('home.contact.company') + $t('home.contact.optional')"
            />
          </div>
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => {
                inputsItems.push(el)
                fileUploader = el
              }"
              v-model:modelValue="contact.file"
              :label="$t('home.contact.upload') + $t('home.contact.optional')"
              fieldType="file"
              :acceptType="[
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/plain'
              ]"
              :acceptFormat="['pdf', 'doc', 'docx', 'txt']"
              acceptSize="20480"
              rules="file"
            />
          </div>
          <div class="col-md-2 hidden-sm"></div>
          <div class="col-sm-12 col-md-5">
            <Input
              :ref="(el) => { inputsItems.push(el) }"
              v-model:modelValue="contact.comments"
              :label="$t('home.contact.comments')"
            />
          </div>
          <div class="col-sm-12 text-center">
            <div v-if="loading" class="spinner primary"></div>
            <div v-if="!loading && status === true" class="text-success">
              Message sent successfully!
            </div>
            <div v-if="!loading && status === false" class="text-error">
              Message sent failed!
            </div>
          </div>
          <div class="col-sm-12 text-center">
            <button
              v-show="!loading"
              type="submit"
              class="primary submit"
            >
              {{ $t('home.contact.submit') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


