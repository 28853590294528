<script setup>
  import { ref, computed, watch, inject } from 'vue'
  import { useRoute, useRouter } from "vue-router";
  import { LottieAnimation } from "lottie-web-vue"
  import fetusIconData from "@/assets/icons/Fetus.json";
  import closeIconData from "@/assets/icons/X.json";
  import burgerMenuData from "@/assets/icons/BurgerMenu.json";

  const route = useRoute()
  const router = useRouter()
  const emitter = inject('emitter')

  const logo = ref()
  const burgerMenu = ref()
  const closeMenu = ref()

  const fixed = ref(true)
  const navbarColor = ref('')
  const loadingTime = ref(3000)
  const loadingEnd = ref(false)
  const startWebSite = ref(false)
  const menuOpen = ref(false)
  const fetusIconInstance = ref('')
  const closeIconInstance = ref('')
  const burgerMenuInstance = ref('')
  const logoLoadingStatus = ref(true)
  const logoLocalStorageName = ref('cnidus.logo.loading')


  const menu = computed(() => {
    const routes = router.options.routes
    return routes.filter(value => {
      return !!value.meta && !!value.meta.label
    })
  })

  const navbarClass = computed(() => {
  return navbarColor.value !== '' ? 'navbar-' + navbarColor.value : ''
  })

  watch(() => route, async () => {
    setNavbarColorInPageChange()
    emitter.emit('logoLoadingEnd')
  })

  watch(loadingEnd, (value) => {
    if (value) {
      emitLogoLoadingEnd()
    }
  })

  const playIcon = (instance) => {
    if (!loadingEnd.value) return
    instance && instance.play()
  }

  const stopIcon = (instance) => {
    if (!loadingEnd.value) return
    instance && instance.stop()

    if (logoLoadingStatus.value) {
      localStorage.setItem(logoLocalStorageName.value, Date.now().toString())
    }
  }

  const setFetusIconInstance = (lottieInstance) => {
    fetusIconInstance.value = lottieInstance
  }
  const setBurgerMenuInstance = (lottieInstance) => {
    burgerMenuInstance.value = lottieInstance
  }
  const setCloseIconInstance = (lottieInstance) => {
    closeIconInstance.value = lottieInstance
  }

  const goToPage = (pageName) => {
    if (!loadingEnd.value) return;
    router.push({ name: pageName }).catch(() => {})
    menuOpen.value = false
  }

  const logoLoading = () => {
    setTimeout(() => {
      startWebSite.value = true
    }, loadingTime.value)

    setTimeout(() => {
      loadingEnd.value = true;
      stopIcon(fetusIconInstance.value)
    }, loadingTime.value + 800)
  }

  const emitLogoLoadingEnd = () => {
    if (loadingEnd.value) {
      emitter.emit('logoLoadingEnd')
    }
  }

  const changeNavbarColor = (event) => {
    navbarColor.value = event.color;
  }

  const setNavbarColorInPageChange = () => {
    let pages = Array.from(document.getElementsByClassName('page-area'))
    navbarColor.value = pages.length === 0 ? 'black' : (
      Array.from(pages[0].classList).filter(value => {
        return value !== 'page-area' && value.match(/page-[a-zA-Z]/)
      })[0] ?? ''
    ).replace('page-', '')
  }

  // logo loading status check by date
  const lastLoadingDate = localStorage.getItem(logoLocalStorageName.value)

  if (!!lastLoadingDate) {
    const difference = (Date.now() - parseInt(lastLoadingDate)) / 1000;

    if(difference / 86400 < 7) {
      loadingTime.value = 0
      logoLoadingStatus.value = false
    }
  }

  logoLoading()

  emitter.on('changeNavbarColor', changeNavbarColor)
  emitter.on('isLoadedWebsite', emitLogoLoadingEnd)
</script>

<template>
  <div class="container" v-bind:class="{ fixed: fixed }">
    <div id="navbar" class="row" v-bind:class="navbarClass">
      <div class="col-sm-12">
        <div
          class="logo"
          v-bind:class="{ loading: !loadingEnd, start: startWebSite }"
        >
          <LottieAnimation
            ref="logo"
            renderer="svg"
            class="logo-img"
            :loop="true"
            :autoplay="true"
            :animation-data="fetusIconData"
            @click.native="goToPage('home')"
            @mouseover.native="playIcon(fetusIconInstance)"
            @mouseleave.native="stopIcon(fetusIconInstance)"
            @getLottieInstance="setFetusIconInstance"
          />
        </div>
        <LottieAnimation
          ref="burgerMenu"
          renderer="svg"
          class="burger-menu"
          :loop="true"
          :autoplay="false"
          :animation-data="burgerMenuData"
          @click.native="menuOpen = true"
          @mouseover.native="playIcon(burgerMenuInstance)"
          @mouseleave.native="stopIcon(burgerMenuInstance)"
          @getLottieInstance="setBurgerMenuInstance"
        />
      </div>
      <transition
        name="fade"
        mode="out-in"
        :duration="800"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div class="navbar-menu" v-if="menuOpen">
          <LottieAnimation
            ref="closeMenu"
            renderer="svg"
            class="close-menu"
            :loop="false"
            :autoplay="false"
            :animation-data="closeIconData"
            @click.native="menuOpen = false"
            @mouseover.native="playIcon(closeIconInstance)"
            @mouseleave.native="stopIcon(closeIconInstance)"
            @getLottieInstance="setCloseIconInstance"
          />
          <ul>
            <li
              v-for="(menuItem, index) in menu"
              :key="'menu_item_' + index"
              @click="goToPage(menuItem.name)"
            >
              {{ $t(menuItem.meta.label) }}
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>
