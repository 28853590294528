import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import language from './languages'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import configs from "@/configs"
import mitt from "mitt"
import WebRTC from './utils/Webrtc/WebRTC'

const emitter = mitt()

const app = createApp(App)

app.use(store)
    .use(router)
    .use(language)
    .use(VueReCaptcha, {
        siteKey: configs.recaptcha.site_key
    })
    .provide('emitter', emitter)
    .provide('webrtc', WebRTC)
    .mount('#app')
