import { createI18n } from "vue-i18n";
import en_GB from "./en-GB/en-GB";

const messages = {
  en: en_GB,
};

const i18n = createI18n({
  locale: "en",
  legacy: false,
  messages: messages,
});

export default i18n;
