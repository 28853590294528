export default {
  title: "At Codenidus, we have the expertise to create a customized web or mobile application",
  tech: {
    categories: {
      frontend: "Front-end",
      mobile: "Mobile",
      backend: "Back-end",
      database: "Database",
      game: "Game",
      infrastructure: "Infrastructure"
    }
  },
  contact: {
    title: "HAVE A PROJECT IN MIND FOR YOUR BUSINESS?",
    areaOfInterest: "AREAS OF INTEREST",
    software: "Software",
    website: "Website",
    mobileApp: "Mobile App",
    itStaffing: "IT Staffing",
    managedServices: "Managed Services",
    name: "NAME",
    phone: "PHONE",
    email: "E-MAIL",
    company: "COMPANY",
    upload: "UPLOAD A FILE",
    comments: "COMMENTS",
    submit: "SUBMIT",
    optional: " (OPTIONAL)"
  }
};
