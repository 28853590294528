import { createRouter, createWebHistory } from 'vue-router'
import webrtcRoutes from './webrtc'

import Home from "@/views/home/Home.vue";

let routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      label: "menu.home"
    }
  },
  {
    path: "/projects/firstsleep",
    name: "firstsleep",
    component: () => import(/* webpackChunkName: "projects" */ "@/views/projects/Firstsleep.vue"),
    meta: {
      label: "menu.projects.firstsleep"
    }
  },
  {
    path: "/projects/xways",
    name: "game-dev",
    component: () => import(/* webpackChunkName: "projects" */ "@/views/projects/XWays.vue"),
    meta: {
      label: "menu.projects.xways"
    }
  },
  {
    path: "/projects/xways/policy",
    name: "xways-policy",
    component: () => import(/* webpackChunkName: "pages" */ "@/views/projects/XWaysPolicy.vue")
  },
  {
    path: "/privacy-policy",
    name: "policy",
    component: () => import(/* webpackChunkName: "pages" */ "@/views/pages/Policy.vue")
  },
  {
    path: "/projects/video-meeting",
    name: "videomeeting",
    component: () =>
        import(/* webpackChunkName: "projects" */ "@/views/projects/VideoMeeting.vue"),
    meta: {
      label: "menu.projects.videomeeting"
    }
  },
  {
    path: "/projects/video-meeting/confirm",
    name: "videomeetingEmailConfirm",
    component: () => import(/* webpackChunkName: "videoconference" */ "@/views/video/VideoEmailConfirm.vue")
  },
  {
    name: "404",
    path: "/page-not-find",
    component: () => import(/* webpackChunkName: "errors" */ "@/views/errors/PageNotFound.vue")
  },
  {
    name: "404-notFind",
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "errors" */ "@/views/errors/PageNotFound.vue")
  }
]

routes = routes.concat(webrtcRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
