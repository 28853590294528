<script setup>
  import { ref } from 'vue'

  const categories = ref([
    {
      name: "frontend",
      icons: [
        { name: "React", icon: "react" },
        { name: "Vue JS", icon: "vuejs" },
        { name: "Javascript", icon: "javascript" }
      ]
    },
    {
      name: "mobile",
      icons: [
        { name: "IOS", icon: "ios" },
        { name: "Android", icon: "android" }
      ]
    },
    {
      name: "backend",
      icons: [
        { name: "Java", icon: "react" },
        { name: "node", icon: "node" },
        { name: "express", icon: "express" },
        { name: "PHP", icon: "php" },
        { name: "LoopBack", icon: "loopback" },
        { name: "Laravel", icon: "laravel" }
      ]
    },
    {
      name: "database",
      icons: [
        { name: "Mango DB", icon: "mangodb" },
        { name: "Redis", icon: "redis" },
        { name: "MySQL", icon: "mysql" },
        { name: "PostgreSQL", icon: "postgresql" }
      ]
    },
    {
      name: "game",
      icons: [{ name: "Unity", icon: "unity" }]
    },
    {
      name: "infrastructure",
      icons: [{ name: "Amazon Web Services", icon: "aws" }]
    }
  ]);
</script>

<template>
  <div class="row page-area page-white technologis">
    <div
      class="col-sm-12 col-md-4 tech-column"
      v-for="(category, index) in categories"
      :key="'tech_category_' + index"
    >
      <h1>{{ $t("home.tech.categories." + category.name) }}</h1>
      <div
        class="logo"
        v-for="(icon, icon_index) in category.icons"
        :key="'tech_icon_' + icon_index"
      >
        <img
          :src="'images/logos/' + icon.icon + '.png'"
          :alt="icon.name"
          :title="icon.name"
        />
      </div>
    </div>
  </div>
</template>


