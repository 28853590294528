export default {
  debug: false,
  base_utl: 'https://www.codenidus.com',
  api_url: 'https://www.codenidus.com/mail/',
  api_token_url: 'https://www.codenidus.com/mail/videoconference/token',
  webrtc_url: 'https://video-conference-api-lzun.onrender.com',
  webrtc_connection: 'video-conference-api-lzun.onrender.com',
  peer_secure: true,
  peer_host: 'rapithwin.com',
  peer_port: '443',
  videoconference_theme: 'Canvasface',
  mediapipe: {
    models: {
      'faceDetector': 'https://www.codenidus.com/models/face',
      'bodySegmentation': 'https://www.codenidus.com/models/selfie'
    },
    fps: 30
  },
  axios: {
    headers: {}
  }
}
