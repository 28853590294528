<script setup>
  import { useRoute } from 'vue-router'
  import Navbar from '@/components/Navbar.vue'
  import Footer from '@/components/Footer.vue'

  const route = useRoute()
</script>

<template>
  <Navbar />
  <router-view
    :key="route.path"
  />
  <Footer />
</template>

<style lang="scss">
@import "./scss/style.scss";
</style>
