export default [
  {
    title: {
      segment_a: '="WEB DEVELOPMENT"',
      segment_b: "",
      icon: {
        name: "WWW",
        width: "180",
        height: "60",
        hover: "web"
      }
    },
    content: [
      {
        title: "FirstSleep Group",
        alias: '"Hotel Portal Web App"',
        color: "color-blue",
        route: "firstslepp",
        description:
          "A search and booking portal for a chain of hotels in Munchen, Germany"
      }
    ]
  },
  {
    title: {
      segment_a: '="GAME DEVELOPMENT"',
      segment_b: "",
      icon: {
        name: "Controller",
        width: "100",
        height: "90",
        hover: "game"
      }
    },
    content: [
      {
        title: "Xways",
        alias: '"A memory improvement game"',
        color: "color-red",
        route: "game-dev",
        description:
          "Defeat enemies by collecting the correct ammunition."
      }
    ]
  },
  {
    title: {
      segment_a: '="Mobile',
      segment_b: 'App"',
      icon: {
        name: "Phone",
        width: "80",
        height: "80",
        hover: "mobile"
      }
    },
    content: [
      {
        title: "Mercul",
        alias: '"Instant Reminder App"',
        color: "color-purple",
        route: "mobile-app",
        description:
          "Mercul app <br />Set your location, message and send"
      }
    ]
  }
];
