<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { useRoute } from 'vue-router'
  import Icon from '@/components/Icon.vue'
  import Typer from '@/components/Typer.vue'
  import Accordion from "@/components/Accordion"
  import ProjectInform from '@/components/ProjectInform'
  import Technologies from './Technologies'
  import Contact from './Contact'
  import ProjectsData from '@/data/pages/homepage/projects'
  import navbarHelper from '@/helpers/navbarHelper.vue'

  const route = useRoute()
  const emitter = inject('emitter')

  const typer = ref()
  const contact = ref()
  const projectsData = ref(ProjectsData)
  const startTyper = ref(false)
  const iconHover = ref({
    web: false,
    game: false,
    mobile: false
  })

  const setTyperStart = () => {
    startTyper.value = true;
  }

  const goToContactSection = () => {
    let positionY = contact.value.$el.offsetTop
    window.scrollTo({
      top: positionY,
      left: 0,
      behavior: "smooth"
    });
  }

  onMounted(() => {
    typer.value.reset()
    emitter.emit('isLoadedWebsite')

    if (!!route.query.goto && route.query.goto === 'contact') {
      goToContactSection()
    }
  })

  emitter.on('logoLoadingEnd', setTyperStart)


</script>

<template>
  <div class="container main-body">
    <navbar-helper />
    <div class="row">
      <div class="col-sm-12"></div>
    </div>
    <div class="row page-area page-black">
      <div class="col-sm-12 big-text dev-padding">
        <Typer
          ref="typer"
          class="big-text"
          :speed="100"
          :pause="2000"
          :start="startTyper"
          oneWay
          :texts="[$t('home.title')]"
          lastCharacterClass="color-gray"
        />
        <div class="line-counter">
          <Accordion
            className="counter"
            v-for="(section, index) in projectsData"
            :key="'project_section_' + index"
          >
            <template #default>
              <div
                class="d-inline-block"
                @mouseover="iconHover[section.title.icon.hover] = true"
                @mouseleave="iconHover[section.title.icon.hover] = false"
              >
                <span>{{ section.title.segment_a }}</span>
                <Icon
                  className="icon-primary"
                  :widthSize="section.title.icon.width"
                  :heightSize="section.title.icon.height"
                  :icon="section.title.icon.name"
                  :hoverIcon="iconHover[section.title.icon.hover]"
                />
                <span>{{ section.title.segment_b }}</span>
              </div>
            </template>
            <template #content>
              <ProjectInform
                v-for="(project, pIndex) in section.content"
                :key="'project_' + index + '_' + pIndex"
                :title="project.title"
                :titleAlias="project.alias"
                :routeName="project.route"
                :color="project.color"
              >
                <template #default>
                  <div class="d-inline" v-html="project.description"></div>
                </template>
              </ProjectInform>
            </template>
          </Accordion>
        </div>
      </div>
    </div>
    <Technologies />
    <Contact ref="contact" />
  </div>
</template>
