<script setup>
  import { defineProps, ref } from 'vue'

  const props = defineProps({
    className: {
      type: String,
      default: ""
    }
  })

  const show = ref(false)
</script>

<template>
  <div
    class="accordion"
    v-bind:class="[props.className, { open: show, close: !show }]"
  >
    <div class="accordion-title unselectable" @click="show = !show">
      <slot name="default"></slot>
    </div>
    <transition name="fade">
      <div v-show="show" ref="item" class="accordion-content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<style>
  .fade-enter {
    opacity: 0;
  }
  .fade-leave-active {
    opacity: 0;
  }
  .fade-leave-active,
  .fade-enter-active {
    transition: opacity 0.4s ease-out;
  }
</style>
