<script setup>
  import { ref, onMounted, onUnmounted, inject } from 'vue'

  const emitter = inject('emitter')
  const areas = ref([])

  const changePageNavbarColor = () => {
    const arr = areas.value.filter(value => {
      const minPos = value.offsetTop;
      const maxPos = value.offsetTop + value.offsetHeight;
      return window.scrollY >= minPos && window.scrollY <= maxPos;
    });

    if (arr.length === 0) return;

    const list = Array.from(arr[arr.length - 1].classList);

    const colors = list.filter(value => {
      return value !== "page-area" && value.match(/page-[a-zA-Z]/);
    });

    const color =  colors.length === 0 ? "" : colors[colors.length - 1].replace("page-", "");

    emitter.emit('changeNavbarColor', { color: color })
  }


  onMounted(() => {
    areas.value = Array.from(document.getElementsByClassName("page-area"))
  })

  onUnmounted(() => {
    window.removeEventListener("scroll", changePageNavbarColor);
  })

  window.addEventListener("scroll", changePageNavbarColor);
</script>

<template></template>