export default {
  home: "Home",
  gameDev: "Game Development",
  mobileApp: "Mobile App",
  webApp: "Web App",
  webDev: "Web Development",
  projects: {
    firstsleep: "Firstsleep",
    xways: "X-Ways",
    mercul: "Mercul",
    videomeeting: "Video Meeting"
  },
  policy: "Privacy Policy",
  contactUs: "Contact Us"
};
