<script setup>
  import { defineProps, defineEmits, defineExpose, computed, ref, watch, getCurrentInstance } from 'vue'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const props = defineProps({
    modelValue: {},
    label: {
      type: String,
      default: null
    },
    fieldType: {
      type: String,
      default: "text"
    },
    rules: {
      default: null
    },
    acceptType: {
      type: Array,
      default: null
    },
    acceptFormat: {
      type: Array,
      default: null
    },
    acceptSize: {
      type: String,
      default: null
    }
  })

  const inputValue = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })

  const emit = defineEmits(['update:modelValue', 'change'])

  const inputMessage = computed(() => {
    return inputStatus.value !== true ? inputStatus.value : ""
  })

  const instance = getCurrentInstance()
  const uuid = ref(instance.uid)

  const inputStatus = ref(true)
  const labelFlag = ref(false)
  const fileUploader = ref()
  const rulesItems = {
    required: () => {
      return !!inputValue.value || t("validation.required")
    },
    email: () => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (
          pattern.test(inputValue.value) || t("validation.email_format")
      );
    },
    phone: () => {
      const pattern = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im
      return (
          pattern.test(inputValue.value) || t("validation.phone_format")
      );
    },
    file: () => {
      if (props.fieldType !== "file")
        return t("validation.file_field")
      if (!inputValue.value) return true

      if (
          !!props.acceptType &&
          props.acceptType.findIndex(x => x === inputValue.value.type) < 0
      ) {
        return t("validation.file_format", {
          format: props.acceptFormat.join(",")
        })
      }

      if (
          !!props.acceptSize &&
          inputValue.value.size > parseInt(props.acceptSize) * 1024
      ) {
        return t("validation.file_size", {
          size: props.acceptSize / 1024
        })
      }

      return true;
    }
  }

  watch(inputValue, (value) => {
    emit('change', value)
  })

  const ruleCheck = (rule) => {
    if (typeof rule === "function") {
      return rule(inputValue.value);
    } else if (typeof rulesItems[rule] === "undefined") {
      return true;
    } else {
      return rulesItems[rule]();
    }
  }

  const rulesCheck = () => {
    inputStatus.value = true;
    if (props.rules === null) return true;

    const rules = typeof props.rules === "object" ? props.rules : [props.rules];

    for (const rule of rules) {
      inputStatus.value = ruleCheck(rule);
      if (inputStatus.value !== true) {
        break;
      }
    }

    return inputStatus.value;
  }

  const eventBlur = () => {
    labelFlag.value = false;
    rulesCheck();
  }

  const fileHandle = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    inputValue.value = files[0];
    rulesCheck();
  }

  const removeFile = () => {
    fileUploader.value.value = ""; // vue bug - reset input data
    inputValue.value = null;
    rulesCheck();
  }

  defineExpose({
    rulesCheck
  })
</script>

<template>
  <div class="form-item">
    <label
      :class="{
        filled: !(inputValue === null || inputValue === '') || labelFlag
      }"
    >
      {{ props.label }}
    </label>
    <input
      v-if="props.fieldType !== 'file'"
      autocomplete="off"
      @focus="labelFlag = true"
      @blur="eventBlur"
      @keyup="rulesCheck"
      v-model="inputValue"
      :class="{ fileUploader: props.fieldType === 'file' }"
    />
    <input
      v-else
      ref="fileUploader"
      type="file"
      :id="'input_' + uuid"
      class="fileUploader"
      @change="fileHandle"
    />
    <label
      v-if="props.fieldType === 'file'"
      :for="'input_' + uuid"
      class="chooseFile"
      >{{ !!inputValue ? inputValue.name : "" }}</label
    >
    <span
      v-if="props.fieldType === 'file' && !!inputValue"
      class="removeFile"
      @click="removeFile"
      >╳</span
    >
    <small v-html="inputMessage"></small>
  </div>
</template>

