<script setup>
  import { defineProps } from 'vue'
  import { useRouter } from "vue-router";

  const router = useRouter()

  const props = defineProps({
    title: {
      type: String,
      require: true
    },
    titleAlias: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "color-blue"
    },
    routeName: {
      type: String,
      default: ""
    }
  })

  const goTo = () => {
    router.push({ name: props.routeName });
    window.scroll(0,0);
  }
</script>

<template>
  <div class="project-inform">
    <div class="project-title" :class="props.color">
      {{ props.title }} <span>{{ props.titleAlias }}</span>
    </div>
    <div class="project-content">
      <slot></slot>
      <span v-if="props.routeName !== ''" class="color-primary">></span>
      <button v-if="props.routeName !== ''" class="primary" @click="goTo">
        {{ $t("general.goTo") }}
      </button>
    </div>
  </div>
</template>
