<script setup>
  import { defineProps, defineEmits, ref, watch } from 'vue'
  import { LottieAnimation } from "lottie-web-vue"


  const props = defineProps({
    icon: {
      type: String,
      default: "Controller"
    },
    className: {
      type: String,
      default: ""
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    widthSize: {
      type: String,
      default: "100"
    },
    heightSize: {
      type: String,
      default: "100"
    },
    hoverIcon: {
      type: Boolean,
      default: false
    }
  })

  const emit = defineEmits(['click'])

  const icon = ref()
  const loadData = ref(false)
  const iconData = ref()
  const iconInstance = ref('')

  watch(() => props.hoverIcon, (value) => {
    if (value) {
      playIcon(iconInstance.value);
    } else {
      stopIcon(iconInstance.value);
    }
  })

  const clickAction = () => {
    emit('onClick')
  }

  const setIconInstance = (lottieInstance) => {
    iconInstance.value = lottieInstance
  }

  const playIcon = () => {
    icon.value.play()
  }
  const stopIcon = () => {
    icon.value.stop()
  }
  const getIconData = async () => {
    iconData.value = await import("@/assets/icons/" + props.icon + ".json")
    loadData.value = true
  }

  getIconData()
</script>

<template>
  <span>
    <LottieAnimation
      v-if="loadData"
      ref="icon"
      renderer="svg"
      class="icon"
      v-bind:class="[props.className]"
      :loop="false"
      :autoplay="false"
      :animationData="iconData"
      v-bind:style="{ width: props.widthSize + 'px', height: props.heightSize + 'px' }"
      @click.native="clickAction"
      @mouseover.native="playIcon(iconInstance)"
      @mouseleave.native="stopIcon(iconInstance)"
      @getLottieInstance="setIconInstance"
    />
    <img
      v-if="props.icon === 'Controller'"
      class="gameDeveloperLogo"
      src="/images/game_development.jpg"
      :width="props.widthSize + 'px'"
      alt="Game Developer"
      title="Game Developer"
    />
  </span>
</template>
